import casual_selfie from "../assets/media/pictures/casual_selfie.png";
import church_fit1 from "../assets/media/pictures/church_fit1.png";
import church_fit2 from "../assets/media/pictures/church_fit2.png";
import church_fit3 from "../assets/media/pictures/church_fit3.png";
import mirrorSelfie2 from "../assets/media/pictures/mirror_selfie2.png";
import wedding_fit from "../assets/media/pictures/wedding_fit.png";

export const aboutPics = [casual_selfie, church_fit1, church_fit2, church_fit3];

export const contentPics = [
  church_fit1,
  mirrorSelfie2,
  church_fit2,
  wedding_fit,
  church_fit3,
  casual_selfie,
];
