import birthday_grwm from "../assets/media/videos/birthday_grwm.mp4";
import black_tie_outfit from "../assets/media/videos/black_tie_outfit.mp4";
import church_grwm1 from "../assets/media/videos/church_grwm1.mp4";
import church_grwm2 from "../assets/media/videos/church_grwm2.mp4";
import church_grwm3 from "../assets/media/videos/church_grwm3.mp4";
import get_urwm from "../assets/media/videos/get_urwm.mp4";
import grwm_bed from "../assets/media/videos/grwm_bed.mp4";
import lip_combo from "../assets/media/videos/lip_combo.mp4";
import modest_fit_pants_edition from "../assets/media/videos/modest_fit_pants_edition.mp4";
import spring_dress from "../assets/media/videos/spring_dress.mp4";
import unwind_with_me from "../assets/media/videos/unwind_with_me.mp4";

export const contentVideos = [
  {
    videoUrl: birthday_grwm,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: black_tie_outfit,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: church_grwm1,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: church_grwm2,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: church_grwm3,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: get_urwm,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: grwm_bed,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: lip_combo,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: modest_fit_pants_edition,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: spring_dress,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: unwind_with_me,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
  {
    videoUrl: church_grwm3,
    contentLink: "https://www.tiktok.com/@kimichella25",
  },
];
